import React, {useState , useEffect} from 'react';
// import {ICompany} from "../../models/ICompany";
import CompanyInfoService from "../../services/CompanyInfoService";
import OrganizationService from "../../services/OrganizationService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import {OrganizationModel} from "../../models/Organization";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DatePicker from "react-datepicker";
import {useSearchParams, useMatch } from "react-router-dom";
import Loader from "../../components/common/Loader";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResourceService from "../../services/ResourceService";
import ResourceImg from '../../components/common/ResourceImg';
import $ from 'jquery';

interface IState{
    loading :boolean;
    orgInfo :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    organization_model : OrganizationModel;
}
interface IProps{}

let OrganizationList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        orgInfo : [],
        errorMessage : "",
        perPage : 10,
        page : 0,
        column : "",
        order : "",
        totalRows : 0,
        organization_model : {
            org_id : "",
            org_name : "",
            org_zip_code : "",
            org_address : "",
            logo : "",
            status : 0
        },
    });
    const loginUser = JSON.parse(localStorage.getItem("user") || '{}');

    const [search, setSearch] = useState("");  
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [current_page, setCurrentPage] = useState(0);
    const [no_of_rows, setNoOfRows] = useState(10);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(650);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    let [show_logo, setShowLogo] = useState("none");
    let [drop_logo, setDropLogo] = useState("block");
    let [logo_name, setLogoName] = useState("");
    const [main_org_list, setMainOrgList] = useState<any[]>([]);
    const [display_main_org, setDisplayMainOrg] = useState("none");


    const getData = async (perPage:number,page:number,search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        OrganizationService.list(perPage,page,search, sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                orgInfo:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
            });
            setMainOrgList(response.data.main_org_list);
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
           
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, orgInfo, perPage,page, column, order, totalRows, errorMessage, organization_model} = state;
 
    useEffect(()=>{
        console.log(loginUser);
        setState({...state, loading: true});
        getData(perPage,page,search, sort, column, order);
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(perPage,page+1,search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(perPage,page,search, sort, column, order);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(perPage,page,e.target.value, sort, column, order);              
                break;
            default:
                break;
        }
        
    };
    let handleSort = (column:any, order:any) => {
        getData(perPage,page,search, sort, column, order);              
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "sort":
                setSort(e.target.value);
                getData(perPage, page, search, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
            case "status":
                setState({
                    ...state,
                    organization_model : {
                        ...state.organization_model,
                       "status" : parseInt(e.target.value)  // input name 'username' set value 'event target value'
                    }
                });  
                if(parseInt(e.target.value) == 1){
                    setDisplayMainOrg("block");
                }else{
                    setDisplayMainOrg("none");
                }
                break;
            default:
                break;
        }
     }; 
    
     const addOrganization = () => {
        setState({
            ...state,
            organization_model : {
                org_id : "",
                org_name : "",
                org_zip_code : "",
                org_address : "",
                logo : "",
                status : 0
            },
        })
        setShowLogo("none");
        setDropLogo("block");
        setOpen(true);
    }

    let updateOrganization = (org_id:any) => {
        OrganizationService.showOrg(org_id).then((data)=>{
           if(data.data.logo != null){
            setShowLogo("block");
            setDropLogo("none");
            setLogoName(data.data.logo);
            localStorage.setItem('logo_image', data.data.logo);
          }else{
            setLogoName("");
          }
          if(parseInt(data.data.status) == 1){
            setDisplayMainOrg("block");
          }else{
            setDisplayMainOrg("none");
          }
           setState({
            ...state,
                    organization_model : {
                        org_id : data.data.org_id,
                        org_name : data.data.org_name,                    
                        org_zip_code : data.data.org_zip_code,
                        org_address : data.data.org_address,
                        logo : data.data.logo,
                        status : data.data.status
                    },
                })
                setOpen(true);
        }).catch((error:any) => {
            console.log(error);
        });
    }

    const deleteOrganization = () => {
        if(selectedRows.length == 0){
            alert("削除したい組織情報をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            orgInfo.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["org_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            OrganizationService.deleteList(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = "/setting/organization/list";           
            }).catch((error) => {
                console.log(error);
                // alert(error.data.info.message);
                // console.log(error.data.info);
            });
        }
    }

    const storeUpdateOrg = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(state.organization_model.org_id == ""){
            OrganizationService.store(state.organization_model, logo_name).then((response) => {  
                if (response.data.success) {
                    localStorage.setItem("logo", response.data.info[0].organization!.logo);
                    localStorage.setItem("org_name",  response.data.info[0].organization!.org_name);
                    window.location.href = "/setting/organization/list";  
                  }            
             }).catch((error) => {
                 if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
             })
        }else{
            OrganizationService.update(state.organization_model, logo_name, state.organization_model.org_id).then((response) => {
                if (response.data.success) {
                    localStorage.setItem("logo", response.data.info[0].organization!.logo);
                    localStorage.setItem("org_name",  response.data.info[0].organization!.org_name);
                    window.location.href = "/setting/organization/list";  
                  }          
             }).catch((error) => {
                 if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
             })
        }
        
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            organization_model : {
                ...state.organization_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });  
    }
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            organization_model : {
                ...state.organization_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });   
    };

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     
    
    }
    
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 
    
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }


    const defaultColumns = [
        {
            name: "org_id",
            label: "編集",
            options: {
                filter: true, 
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updateOrganization(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
        },
        {
            name: "org_id",
            label: "組織ID",
        },
        {"name" : 'org_name', "label" : "組織名",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            name: "status",
            label: "本社・支店",
        },
        {"name" : 'org_zip_code', "label" : "郵便",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {"name" : 'org_address', "label" : "住所",
            class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
       
    ]


    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
         
            const handleClick = () => {
                
                
            };
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                orgInfo.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "組織情報" +date;
                }
                let headers = [
                    {"key" : 'org_id', "label" : "会社ID"},
                    {"key" : 'org_name', "label" : "会社名"},
                    {"key" : 'org_zip_code', "label" : "郵便"},
                    {"key" : 'org_address', "label" : "住所"},
                ];
               
              
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        // onRowClick: (rowData:any, rowState:any) => {
        //    goToDetail(rowData, rowState);
        // },
        onCellClick : (colData:any, cellMeta:any) =>{
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            // handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
        // onChangePage: (page) => {
        // this.setState({page}, () => {
        //     this.sort(this.state.sortOrder.name, this.state.sortOrder.direction);
        // });
        // }
    };
 
     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: 'none',
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
    //    const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };

    const upload_logo = function (e:any) {
        e.preventDefault();
    
      }
    let editLogo = function(e:any) {
        e.preventDefault();
        setShowLogo("none");
        setDropLogo("block");
    }
      const logoExplorer = function (e:any) {
        e.preventDefault();
        $('#selectlogo').click();
      }
     const changeLogo = function(e:any) {
      let file_name = e.target.files[0].name;
        logo_upload(e.target.files[0],file_name);
        e.target.value = null;
    };
     const logo_upload = function(file_obj:any, name:any) {
        ResourceService.apiUpload(file_obj, 'logo/', name).then((res:any) => {
        setShowLogo("block");
        setDropLogo("none");
        setLogoName(name);
        localStorage.setItem('logo_image', name);
        $(".img-content img").attr("src" , ResourceService.url(`logo/${name}`))
      });
    }

    
    const edit_modal_box = (
        <div>
            <div className="row">
                    <div className="col-6">
                        <h3>組織情報{(organization_model['org_id'] == "")? "登録": "編集"}</h3>
                    </div>
                    <div className="col-6">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form" onSubmit={storeUpdateOrg}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="org_id" value={organization_model["org_id"]} />
                     {/* <div className="row">
                        <div className="col-12 input-box">
                            <input type="hidden" name="logo" value={organization_model['logo']} className="uploaded_profile" />
                                <div className="img-content show_logo" style={{display: show_logo }}>
                                    <button className="edit_btn" onClick={editLogo}><i className="fas fa-edit"></i></button>
                                    <ResourceImg remoteSrc={(localStorage.getItem('logo_image') != undefined  && localStorage.getItem('logo_image') !== null
                                    && localStorage.getItem('logo_image') != '')?`logo/${localStorage.getItem('logo_image')}`: ''} src={'images/businessman.jpg'}  className="img-circle" alt="icons"  />
                                </div>
                                
                                <div id="drop_file_zone" className="drop_logo" style={{display: drop_logo }} data-id="1" onDrop={upload_logo}>
                                    <div id="drag_upload_file">
                                        <p>顔写真をドラッグしてください。</p>
                                        <p>または</p>
                                        <p><input type="button" value="顔写真を選択してください。" onClick={logoExplorer} /></p>
                                        <input type="file" name="logo" id="selectlogo"  onChange={changeLogo} />
                                    </div>
                                </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">組織名:</label> 
                                <input type="text" className="form-control" name="org_name"  value={organization_model['org_name']}  onChange={updateInput} /> 
                            </div>       
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" >郵便:</label> 
                                <input type="text" className="form-control" name="org_zip_code"  value={organization_model['org_zip_code']}  onChange={updateInput} />
                            </div>          
                        </div>
                    </div>      
                    <div className="row">
                        <div className="col-12 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">住所:</label> 
                                <textarea className="form-control" rows={2} name = "org_address" id= "withdraw_reason" aria-label="With textarea" value={organization_model['org_address']} onChange={updateTextArea}></textarea>
                            </div>       
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" >本社・支店:</label> 
                                <select name="status" onChange={onSelect} value={organization_model["status"]} className="form-control">
                                   <option value=""></option>
                                   <option value="0">本社</option>
                                   <option value="1">支店</option>
                                </select>                           
                            </div>
                        </div>  
                        <div className="col-6" style={{display:display_main_org}}>
                            <div className="form-group input-box">
                                <label htmlFor="exampleInputEmail1" >メイン会社:</label> 
                                <select name="status" onChange={onSelect} value={organization_model["status"]} style={{width:"100%"}}>
                                    <option value=""></option>
                                    {main_org_list.map(main_org => {
                                            return(<option value={main_org.org_id}> {main_org.org_name}</option>)
                                        })
                                    }
                                </select>                           
                            </div>          
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">{(organization_model['org_id'] == "")? "登録": "更新"}</button>
                        </div>
                    </div>
                </form>
        </div>
    )

    return(
        
       <React.Fragment>
         <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(組織ID、組織名を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col className="col-2 mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                <Row>
                        <Col xs={12} className="pc-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <h3 className='header-left-design'>
                                 組織情報一覧
                            </h3>
                            </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="float-end">
                            <div className="anken-add action  mt-4">
                                <button type="button" className="btn_standard ml_20 btn-sm" onClick={addOrganization}>追加</button>
                                <button type="button" className="btn_danger ml_20 btn-sm"  onClick={deleteOrganization}>削除</button>
                            </div>
                        </Col>
                    </Row> 
                    <Row className="pt-2 pb-2 ">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} >
                            <div style={{display:"inline"}}>
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                            </div>
                          
                      
                                <div className="float-right">
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button>
                                </div>
                            </Col>
                    </Row> 
                   
                    <div className='datatable freeze-company-info-header freeze-company-info-cell company-info-frozen-columns xs-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {orgInfo}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {orgInfo}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
            </Container>

            <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}
            >
                {edit_modal_box}
            </Box>
        </Modal>  

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}
            >
                {edit_modal_box}
            </Box>
        </Modal>               
        </React.Fragment>
    )

}
export default OrganizationList;