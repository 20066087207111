import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import HomePage from "../pages/home/HomePage";
import Home from "../pages/home/Home";
import PersonalInformationPolicy from "../pages/home/PersonalInformationPolicy";
import SecurityPolicy from "../pages/home/SecurityPolicy";
import { RouteType } from "./config";
import DefaultPage from "../pages/dashboard/DefaultPage";
import DashboardIndex from "../pages/dashboard/DashboardIndex";
import ChangelogPage from "../pages/changelog/ChangelogPage";
import AnalyticsPage from "../pages/dashboard/AnalyticsPage";
import SaasPage from "../pages/dashboard/SaasPage";
import ComponentPageLayout from "../pages/component/ComponentPageLayout";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import HomeIcon from '@mui/icons-material/Home';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AlertPage from "../pages/component/AlertPage";
import ButtonPage from "../pages/component/ButtonPage";
import InstallationPage from "../pages/installation/InstallationPage";
import DocumentationPage from "../pages/documentation/DocumentationPage";
import PersonList from "../pages/person/PersonList";
import PersonPageLayout from "../pages/person/PersonPageLayout";
import Persons from "../pages/person/Persons";
import PersonsCreate from "../pages/person/PersonsCreate";
import PersonsDetail from "../pages/person/PersonsDetail";
import PersonsUpdate from "../pages/person/PersonsUpdate";
// import Example from "../pages/person/Example";


import AssignmentByPerson from "../pages/person/AssignmentByPerson";

import FileUpload from "../pages/person/FileUpload";


import CaseList from "../pages/case/CaseList";
import CaseCreate from "../pages/case/CaseCreate";
import CaseDetail from "../pages/case/CaseDetail";
import CasePageLayout from "../pages/case/CasePageLayout";
import Cases from "../pages/case/Cases";
import AssignmentByCase from "../pages/case/AssignmentByCase";
import AssignmentEditByCase from "../pages/case/AssignmentEditByCase";

import CompanyInfoList from "../pages/setting/CompanyInfoList";
import OrganizationList from "../pages/setting/OrganizationList";
import SettingPageLayout from "../pages/setting/SettingPageLayout";
import UserList from "../pages/setting/UserList";
import LoginForm from "../components/LoginForm";
import TwoFactorForm from "../components/TwoFactorForm";
import ResetPasswordForm from "../components/ResetPasswordForm";
import NewPasswordForm from "../components/NewPasswordForm";

import ProspectPageLayout from "../pages/prospect/ProspectPageLayout";
import ProspectList from "../pages/prospect/ProspectList";
import ProspectCreate from "../pages/prospect/ProspectCreate";

import ProcurementPageLayout from "../pages/procurement/ProcurementPageLayout";
import HRList from "../pages/procurement/HRList";
import HRPerformanceList from "../pages/procurement/HRPerformanceList";
import SaleList from "../pages/procurement/SaleList";
import SalePerformanceList from "../pages/procurement/SalePerformanceList";
import PartnerList from "../pages/procurement/PartnerList";
import WorkList from "../pages/management/WorkList";
import WorkCreate from "../pages/management/WorkCreate";
import ProcedureList from "../pages/management/ProcedureList";
import SVGDownload from "../pages/management/SVGDownload";
import SVGDownloadMail from "../pages/management/SVGDownloadMail";
import KeyConfirmation from "../pages/management/KeyConfirmation";

import ReminderPageLayout from "../pages/reminder/ReminderPageLayout";
import ReminderList from "../pages/reminder/ReminderList";
import ReminderCreate from "../pages/reminder/ReminderCreate";
import GroupRoomList from "../pages/reminder/GroupRoomList";
import GroupRoomCreate from "../pages/reminder/GroupRoomCreate";

import SaleryPageLayout from "../pages/salery/SaleryPageLayout";
import SaleryList from "../pages/salery/SaleryList";
import SaleryCreate from "../pages/salery/SaleryCreate";
import SaleryDetailList from "../pages/salery/SaleryDetailList";
import SaleryDetailUpdate from "../pages/salery/SaleryDetailUpdate";

import AssignmentPageLayout from "../pages/assignment/AssignmentPageLayout";
import AssignmentCaseList from "../pages/assignment/AssignmentCaseList";
import AssignmentPersonList from "../pages/assignment/AssignmentPersonList";
import AssignmentApproveList from "../pages/assignment/AssignmentApproveList";
import {Routes, Navigate} from "react-router-dom";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';


import TestPageLayout from "../pages/test/TestPageLayout";
import Test from "../pages/test/Test";
import PurchaseOrderList from "../pages/management/PurchaseOrderList";
import BillOrderList from "../pages/management/BillOrderList";
import DropDownList from "../components/common/DropDownList";
import ProfileChange from "../components/common/ProfileChange";
import ProfileChangeWrapper from "../components/common/ProfileChangeWrapper";
import ForbiddenPage from "../components/common/403";
import NotFoundPage from "../components/common/404";

const login_user =   (localStorage.getItem("user") !== null)?JSON.parse(localStorage.getItem("user") || '{}'):{};
const shouldhomeDisplaySidebar = [1,2,3,4,6,9].includes(login_user.role);
const shouldcaseDisplaySidebar = [1, 2, 3, 4, 7, 8, 9].includes(login_user.role);
const shouldcaseListDisplaySidebar = [1, 2, 3, 4, 8,9].includes(login_user.role);
const shouldpersonDisplaySidebar = [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(login_user.role);

const shouldassignmentListDisplaySidebar = [1,2,3,4,9].includes(login_user.role);
const shouldassignmentApprovalJinJiListDisplaySidebar = [9].includes(login_user.role);

const shouldmeetingListDisplaySidebar = [1,2,3,4,5,6,9].includes(login_user.role);
const shouldsaleListDisplaySidebar = [2,5,6,9].includes(login_user.role);
const shouldsalePerformanceListDisplaySidebar = [2,9].includes(login_user.role);

const shouldprocureMentListDisplaySidebar = [3,4,5,6,9].includes(login_user.role);
const shouldHRListDisplaySidebar = [4,9].includes(login_user.role);

const shouldGenbaDisplaySidebar = [1, 2, 3, 4, 5, 6, 9].includes(login_user.role);
const shouldpwpListDisplaySidebar = [5, 6, 9].includes(login_user.role);

const shouldpurchaseOrderListDisplaySidebar = [3, 4, 6, 9].includes(login_user.role);
const shouldbillListDisplaySidebar = [1, 2, 6, 9].includes(login_user.role);

const shouldsettingDisplaySidebar = ![7,8].includes(login_user.role);
const shouldsettingProfileDisplaySidebar = ![9].includes(login_user.role);
const shouldsettingOrganizationDisplaySidebar = [9].includes(login_user.role);

const appRoutes: RouteType[] = [
  {
    path: "/403",
    index: false,
    element: <ForbiddenPage />,
    state: "forbidden"
  },
  {
    path: "/404",
    index: false,
    element: <NotFoundPage />,
    state: "notfound"
  },
  {
    path: "/verify",
    index: true,
    element: <TwoFactorForm />,
    state: "twofactor"
  },
  {
    path: "/resetpassword",
    index: true,
    element: <ResetPasswordForm />,
    state: "resetpassword"
  },
  {
    path: "/newpassword",
    index: true,
    element: <NewPasswordForm />,
    state: "newpassword"
  },
  {
    path: "/keyconfirm/:id",
    element: <KeyConfirmation />,
    state: "keyconfirm",
    index: false,
  },
  {
    path: "/download/encryptsvg/:id",
    element: <SVGDownloadMail />,
    state: "svgdownload",
    index: false,
  },
  
  {
    path: "/",
    index: false,
    element: <Navigate to="/home" />,
    state: ""
  },
  // {
  //   path: "/",
  //   index: false,
  //   element: <LoginForm />,
  //   state: "login"
  // },
  // {
  //   path: "/test",
  //   element: <PersonPageLayout />,
  //   state: "test",
  //   sidebarProps: {
  //     displayText: "Test",
  //     icon: <HomeIcon />
  //   },
  //   child: [
  //     {
  //       path: "/test",
  //       element: <Example />,
  //       state: "test.index",
  //       sidebarProps: {
  //         displayText: "TEST"
  //       }
  //     }
  //   ]
  // },
  {
    path: "/home1",
    element: <Home />,
    state: "page",
    index: true,
  },
  {
    path: "/home",
    element: <HomePage />,
    state: "home",
    sidebarProps: {
      displayText: "ホーム",
      icon: <HomeIcon />
    },
    child: [
      {
        path: "/home",
        element: <Home />,
        state: "home.index",
        sidebarProps: shouldhomeDisplaySidebar ? {
          displayText: "ホーム"
        } : undefined,
      },
      {
        path: "/home/personal-info-policy",
        element: <PersonalInformationPolicy/>,
        state: "home.personal.info.policy",
        sidebarProps: {
          displayText: "個人情報保護方針"
        },
      },
      {
        path: "/home/security-policy",
        element: <SecurityPolicy/>,
        state: "home.security.policy",
        sidebarProps: {
          displayText: "情報セキュリティ方針"
        },
      }
    ]
  },
  {
    path: "/cases",
    element: <CasePageLayout />,
    state: "cases",
    sidebarProps: shouldcaseDisplaySidebar ? {
      displayText: "プロジェクト",
      icon: <BusinessCenterIcon />
    } : undefined,
    child: [
      {
        path: "/cases",
        element: <Cases />,
        state: "cases.index",
        sidebarProps: shouldcaseDisplaySidebar ? {
          displayText: "案件情報",
        } : undefined,
      },
      {
        path: "/cases/list",
        element: <CaseList />,
        state: "cases.list",
        sidebarProps: shouldcaseListDisplaySidebar ? {
          displayText: "案件一覧",
        } : undefined,
      },
      {
        path: "/cases/create",
        index: false,
        element: <CaseCreate />,
        state: "cases.create"
      },
      {
        path: `/cases/:encode`,
        index: false,
        element: <CaseDetail />,
        state: "cases.details",
      },
      {
        path: `/cases/assignment/:encode`,
        index: false,
        element: <AssignmentByCase />,
        state: "case.assignment",
      },
      {
        path: `/cases/assignment/edit/:assignment_id`,
        index: false,
        element: <AssignmentEditByCase />,
        state: "case.assignment.edit",
      },
      {
          path: `/cases/:id/edit`,
          index: false,
          element: <CaseCreate />,
          // element: <CasesUpdate />,
          state: "case.update",
      },
      {
        path: `/cases/:id/edit?process=copy`,
        index: false,
        element: <CaseCreate />,
        // element: <CasesUpdate />,
        state: "case.update",
      }
    ]
  },
  {
    path: "/persons",
    element: <PersonPageLayout />,
    state: "persons",
    sidebarProps: shouldpersonDisplaySidebar ? {
      displayText: "エンジニア",
      icon: <AccountCircleIcon />
    } : undefined,
    child: [
      {
        path: "/persons",
        element: <Persons />,
        state: "persons.index",
        sidebarProps: {
          displayText: "要員情報"
        },
      },
      {
        path: "/persons/list",
        element: <PersonList />,
        state: "persons.list",
        sidebarProps: {
          displayText: "要員一覧"
        }
      },
      {
        path: "/persons/create",
        index: false,
        element: <PersonsCreate />,
        state: "persons.create"
      },
      {
        path: `/persons/:encode`,
        index: false,
        element: <PersonsDetail />,
        state: "persons.details",
      },
      {
        path: `/persons/:id/edit`,
        index: false,
        // element: <PersonsUpdate />,
        element: <PersonsCreate />,
        state: "persons.update",
      },
      {
        path: `/persons/:id/edit?process=copy`,
        index: false,
        element: <PersonsCreate />,
        // element: <CasesUpdate />,
        state: "persons.update",
      },
      {
        path: `/persons/assignment/:encode`,
        index: false,
        element: <AssignmentByPerson />,
        state: "persons.assignment",
      }
    ]
  },
  {
    path: "/assignment",
    element: <AssignmentPageLayout />,
    state: "assignment",
    sidebarProps: shouldassignmentListDisplaySidebar ? {
      displayText: "アサイン管理",
      icon: <PanToolAltIcon />
    } : undefined,
    child: [
      {
        path: "/assignment/list/case",
        element: <AssignmentCaseList />,
        state: "assignment.assignmentcaselist",
        sidebarProps: shouldassignmentListDisplaySidebar ? {
          displayText: "案件割り当て",
        } : undefined,
      },
      {
        path: "/assignment/list/person",
        element: <AssignmentPersonList />,
        state: "assignment.assignmentpersonlist",
        sidebarProps: shouldassignmentListDisplaySidebar ? {
          displayText: "要員割り当て",
        } : undefined,
      },
      {
        path: "/assignment/list/approve",
        element: <AssignmentApproveList />,
        state: "assignment.assignmentapprovelist",
        sidebarProps: shouldassignmentApprovalJinJiListDisplaySidebar ? {
          displayText: "割り当て承認",
        } : undefined,
      }
    ]
  },
  {
    path: "/prospect",
    element: <ProspectPageLayout />,
    state: "prospect",
    sidebarProps: shouldmeetingListDisplaySidebar ? {
      displayText: "見込客",
      icon: <PeopleIcon />
    }: undefined,
    child: [
      {
        path: "/prospect/list",
        element: <ProspectList />,
        state: "prospect.list",
        sidebarProps: {
          displayText: "見込客一覧"
        },
      },
      {
        path: "/prospect/create",
        element: <ProspectCreate />,
        state: "prospect.create",
        index: false,
      },
      {
        path: `/prospect/:id/edit`,
        index: false,
        // element: <PersonsUpdate />,
        element: <ProspectCreate />,
        state: "prospect.update",
      },
    ]
  },
  {
    path: "/sale",
    element: <ProcurementPageLayout />,
    state: "sales",
    sidebarProps: shouldsaleListDisplaySidebar ? {
      displayText: "営業管理",
      icon: <ShoppingCartIcon />
    }: undefined,
    child: [
      {
        path: "/sale/list",
        element: <SaleList />,
        state: "sales.list",
        sidebarProps: shouldsalePerformanceListDisplaySidebar ? {
          displayText: "営業管理"
        }: undefined,
      },
      {
        path: "/sale/performance",
        element: <SalePerformanceList />,
        state: "sales.performance",
        sidebarProps: shouldsalePerformanceListDisplaySidebar ? {
          displayText: "営業実績管理"
        }: undefined,
      },
      {
        path: `/sale/partners/list/1`,
        index: false,
        element: <PartnerList />,
        state: "sales.partnerslist",
        sidebarProps: shouldsaleListDisplaySidebar ? {
          displayText: "取引先一覧"
        }: undefined,
      }
    ]
  },
  {
    path: "/procurement",
    element: <ProcurementPageLayout />,
    state: "procurement",
    sidebarProps: shouldprocureMentListDisplaySidebar ? {
      displayText: "調達管理",
      icon: <ShoppingCartIcon />
    }: undefined,
    child: [
      {
        path: "/procurement/hrs/list",
        element: <HRList />,
        state: "procurement.hrslist",
        sidebarProps: shouldHRListDisplaySidebar ? {
          displayText: "HR管理"
        }: undefined,
      },
      {
        path: "/procurement/hrs/performance",
        element: <HRPerformanceList />,
        state: "procurement.hrsperformance",
        sidebarProps: shouldHRListDisplaySidebar ? {
          displayText: "調達実績管理"
        }: undefined,
      },
      {
        path: `/procurement/partners/list/2`,
        element: <PartnerList />,
        state: "procurement.partnerslist",
        sidebarProps: shouldprocureMentListDisplaySidebar ? {
          displayText: "パートナー一覧"
        }: undefined,
      }
    ]
  },
  {
    path: "/management",
    element: <ProcurementPageLayout />,
    state: "management",
    sidebarProps: shouldGenbaDisplaySidebar ? {
      displayText: "管理部",
      icon: <SportsEsportsIcon />
    }: undefined,
    child: [
      {
        path: "/management/works/list",
        element: <WorkList />,
        state: "management.workslist",
        sidebarProps: shouldpwpListDisplaySidebar ? {
          displayText: "稼働管理"
        }: undefined,
      },
      {
        path: "/management/work/create",
        element: <WorkCreate />,
        state: "work.create",
        index: false,
      },
      {
        path: `/management/work/:id/edit`,
        index: false,
        element: <WorkCreate />,
        state: "work.update",
      },
      {
        path: "/management/procedures/list",
        element: <ProcedureList />,
        state: "management.procedureslist",
        sidebarProps: shouldpwpListDisplaySidebar ? {
          displayText: "受発注手続き"
        }: undefined,
      },
      {
        path: "/management/download/svg/:id",
        element: <SVGDownload />,
        state: "management.svgdownload",
        index: false,
      },
      {
        path: "/management/purchaseorder/list",
        element: <PurchaseOrderList />,
        state: "management.purchaseorderlist",
        sidebarProps: shouldpurchaseOrderListDisplaySidebar ? {
          displayText: "注文書一覧"
        }: undefined,
      },
      {
        path: "/management/bill/list",
        element: <BillOrderList />,
        state: "management.billorderlist",
        sidebarProps: shouldbillListDisplaySidebar ? {
          displayText: "請求書一覧"
        }: undefined,
      },
      {
        path: "/management/partners/list",
        index: false,
        element: <PartnerList />,
        state: "management.partnerslist",
        sidebarProps: shouldpwpListDisplaySidebar ? {
          displayText: "取引先/パートナー一覧"
        }: undefined,
      },
     
    
    ]
  },
  {
    path: "/salery",
    element: <SaleryPageLayout />,
    state: "salery",
    sidebarProps: shouldassignmentApprovalJinJiListDisplaySidebar ? {
      displayText: "人事部",
      icon: <SportsEsportsIcon />
    }: undefined,
    child: [
      {
        path: "/salery/list",
        element: <SaleryList />,
        state: "salery.list",
        sidebarProps: {
          displayText: "給料情報一覧"
        },
      },
      {
        path: "/salery/create",
        element: <SaleryCreate />,
        state: "salery.create",
        index: false,
      },
      {
        path: `/salery/:id/edit`,
        index: false,
        element: <SaleryCreate />,
        // element: <CasesUpdate />,
        state: "salery.update",
      },
      {
        path: "/salery/detail",
        element: <SaleryDetailList />,
        state: "salery.detail",
        sidebarProps: {
          displayText: "給料明細"
        },
      },  
      {
        path: `/salery/detail/:id/edit`,
        index: false,
        element: <SaleryDetailUpdate />,
        state: "salery.detail.update",
      },  
    
    ]
  },
  {
    path: "/interview",
    element: <ReminderPageLayout />,
    state: "interview",
    sidebarProps: shouldmeetingListDisplaySidebar ? {
      displayText: "面接",
      icon: <GroupAddIcon />
    } : undefined,
    child: [
      {
        path: "/interview/reminders",
        element: <ReminderList />,
        state: "interview.reminders",
        sidebarProps: {
          displayText: "面談通知管理"
        },
      },
      {
        path: "/interview/reminders/create",
        element: <ReminderCreate />,
        state: "interview.reminders.create",
        index: false,
      },
      {
        path: `/interview/reminder/:id/edit`,
        index: false,
        // element: <PersonsUpdate />,
        element: <ReminderCreate />,
        state: "interview.reminders.update",
      },
      {
        path: "/interview/grouproom",
        element: <GroupRoomList />,
        state: "interview.grouproom",
        sidebarProps: {
          displayText: "ビデオチャット"
        },
      },
      {
        path: "/interview/grouprooms/create",
        element: <GroupRoomCreate />,
        state: "interview.grouproom.create",
        index: false,
      },
      {
        path: `/interview/grouproom/:id/edit`,
        index: false,
        element: <GroupRoomCreate />,
        state: "interview.grouproom.update",
      },
    ]
  },
  {
    path: "/setting",
    element: <SettingPageLayout />,
    state: "setting",
    sidebarProps: shouldsettingDisplaySidebar ? {
      displayText: "設定",
      icon: <SettingsSuggestIcon />
    }: undefined,
    child: [
      {
        path: "/setting/users/list",
        element: <UserList />,
        state: "setting.userslist",
        sidebarProps: shouldassignmentApprovalJinJiListDisplaySidebar ? {
          displayText: "利用者設定"
        }: undefined,
      },
      {
        path: "/setting/company-info/list",
        element: <CompanyInfoList />,
        state: "company-info.list",
        
        sidebarProps: shouldassignmentApprovalJinJiListDisplaySidebar ? {
          displayText: "会社情報一覧"
        }: undefined
      },
      {
        path: "/setting/organization/list",
        element: <OrganizationList />,
        state: "organization.list",
        sidebarProps: shouldsettingOrganizationDisplaySidebar ?{
          displayText: "組織情報一覧"
        } : undefined,
      },
      {
        path: "/setting",
        element: <ProfileChangeWrapper />, 
        state: "profile-change",
        sidebarProps: shouldsettingProfileDisplaySidebar ? {
          displayText: "プロフィール変更"
        }: undefined
      }
    ]
  },
 
];

export default appRoutes;