import React, {useState, useContext} from 'react';
import AuthService from "../services/AuthService";
import '../index.css';
import { store } from '../redux/store';
import { Hidden } from '@mui/material';
import { Console } from 'console';
import $ from 'jquery';

import {AppStateContext, AppConstStateActions} from '../redux/AppStore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../components/common/Helpers";

interface IProps{}

let ResetPasswordForm:React.FC<IProps> = () => {
    const appStateContext = useContext(AppStateContext);

    if(!appStateContext) {
      throw Error('CurrentContext must be used whith a CounterProvider.');
    }
  
    const { appDatas, appAction } = appStateContext;
    let [email, setEmail] = useState("");
    let [display, setDisplay] = useState("none");
    let [text , setText] = useState("");
    const [btnColor, setBtnColor] = useState("#444444ab");
    const [disabled, setDisabled] = React.useState(false);

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setEmail(event.target.value);
    };

   

    //confirm two verification code
    let store = (event : any):void => {
         // click event type declare in React
        event.preventDefault();
        setBtnColor("#44444459");
        setDisabled(true);
        let data = {};
        data["email"] = email;
        AuthService.resetPassword(data).then((response) => {
            console.log(response);
            if(response.status == 200){
                setDisplay("block");
                setText("パスワード再設定メールを送信しました。メールを確認して60分以内にパスワードを再設定してください。");
                setDisabled(false);
                setBtnColor("#444444ab");
            }

        }).catch((error) => {
            console.log(error);
            setDisplay("block");
            setText("メールアドレスが間違っています。もう一度やり直してください。");
            setDisabled(false);
            setBtnColor("#444444ab");
        })
    };

    return(
        <React.Fragment>
            <div  className="g-0" style={{overflow:"hidden"}}> 
                <div className="card">
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className=" xs-hidden">
                        <img src="/images/login.png" alt="profile-img"  style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
                        <div className="login-content" style={{zIndex: 999}}>即戦力人材を活用して、<br/>迅速な課題解決が<br/>可能になります。</div>
                    </Col>
                      <Col  xxl={6} xl={6} md={6} sm={6} xs={12}>
                            <div className="card-body two-factor-form ">
                                <Row>
                                    <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                        <div className="mt_10 border-0">
                                            <div className="two-factor-header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                                <h1 className="p-3">SESMart</h1>
                                                <p>登録したメールアドレスを入力してください。</p>
                                                {/* <p>
                                                受け取っていない場合,  <a style={{color : "#82817F"}}
                                                    href="" onClick={(event) => {
                                                    event.preventDefault();
                                                    verifyResend();
                                                }}>ここを押す</a> */}
                                                {/* <a href="{{ route('verify.resend') }}">ここを押す</a> */}
                                                {/* </p> */}
                                            </div>
                                            <div className="card-body two-factor-form">
                                                <form className="form" >
                                                        <div className="factor_form" style={{display:display}}>
                                                            <small className="text-center success_txt">{text}</small>
                                                        </div>
                                                    {/* <span className="two-factor-code-error" ></span> */}
                                                        <div className="form-group text-center">
                                                            <input type="text" name='email' id= 'email' onChange={updateInput} value={email} placeholder="メールアドレス"  className="form-control mb-3 two_factor_code"  required autoFocus/>
                                                        </div>
                                                        <div className="text-center mt-5">
                                                            <button type="button" onClick={store} className="login-btn" style={{backgroundColor: btnColor}} disabled={disabled}>送信</button>
                                                            <a href="/" style={{fontSize:"15px",color : "#82817F"}}>ログインページへ</a>
                                                        </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
                </div>
            </div>                                    
        </React.Fragment>
    )
};
export default ResetPasswordForm;