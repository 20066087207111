import React, {useState, useContext, useEffect} from 'react';
import {IUser} from "../models/IUser";
import AuthService from "../services/AuthService";
import '../index.css';
import '../login.css';
import Modal from '@mui/material/Modal';
import { useNavigate , Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {showErrorInfo, PCModalStyle, MobileModalStyle, PolicyText} from "../components/common/Helpers";
import {AppStateContext, AppConstStateActions} from '../redux/AppStore';
import $ from 'jquery';


interface IState{
    user : IUser
}
interface IProps{}

let LoginForm:React.FC<IProps> = () => {
    const appStateContext = useContext(AppStateContext);

    if(!appStateContext) {
      throw Error('CurrentContext must be used whith a CounterProvider.');
    }
	const navigate = useNavigate();
    const { appDatas, appAction } = appStateContext;
    let [state , setState] = useState<IState>({
        user : {
            email : '',
            password : ''
        }
    });

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            user : {
                ...state.user,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    };
    let [login_error, setLoginError] = useState("none");
    const [open, setOpen] = React.useState(false);
	const [url,setURL] = useState("http://localhost:3000/verify");
    const [btnColor, setBtnColor] = useState("#444444ab");
    const [disabled, setDisabled] = React.useState(false);

    const handleClose = () => setOpen(false);

	

    // const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };

    let login =  (event:any) =>{// click event type declare in React
        event.preventDefault();
		setBtnColor("#44444459");
		setDisabled(true);

		let datas = {};
		datas["email"]= state.user.email;
		datas["password"] = state.user.password;
		datas["url"] = url;
		// datas["server_url"] = url;

        AuthService.login(datas).then((response) => {
			setLoginError("none");
			setBtnColor("#444444ab");
			$(".login-form .form #star").remove();

            if(response.data.status == 200){
                setLoginError("none");
                // localStorage.setItem("token", response.data.token);
                localStorage.setItem("login_email", datas["email"]);
				localStorage.setItem("login_pwd", datas["password"]);
				// localStorage.setItem("datas", JSON.stringify(response.data.datas));
				// window.location.href = "/verify";
				navigate('/verify');
                // AuthService.resourceLogin(response.data.token, "/cases/list");
            }else if(response.data.status == 401){

				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>"+response.data.message+"</span>"); 
			}else{
				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>メールアドレスまたはパスワードが間違っています。</span>"); 
            }
            
        }).catch((error) => {
			setDisabled(false);
            setLoginError("block");
            // if(error.response.data){
            //     showErrorInfo(error.response.data);
            // }

        })
    };

	const  policyModal = (
		<div className="row">
		<div className="col-12">
			<div className="modal-content">
						<div className="modal-header">
						<h3 className="modal-title" id="exampleModalLabel">SESMart企業用　利用規約</h3>
						<button type="button" className="modal_close" onClick={handleClose}>X</button>
						{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button> */}
						</div>
						{PolicyText}
						<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Close</button>
						</div>
					</div>
		</div>
	</div>
	)

    return(
        <React.Fragment>
			<div className="g-0" style={{overflow:"hidden"}}>
            {/* <div className="card" style={{overflow:"hidden"}}> */}
				<Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className=" xs-hidden">
                        <img src="/images/login.png" alt="profile-img"  style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
                        <div className="login-content" style={{zIndex: 999}}>即戦力人材を活用して、<br/>迅速な課題解決が<br/>可能になります。</div>
                    </Col>
                    <Col  xxl={6} xl={6} md={6} sm={6} xs={12}>
                        <div className="card-body login-form login-container">
                        <Row>
                            <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                <div className="card mt_10 border-0">
                                    <div className="card-header header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                        <h1 className="p-3">SESMart</h1>
                                        <p>Welcome to the SESMart Sales System</p>
                                    </div>
                                    <div className="card-body">
                                        <form className="form text-center">
                                        	<span className="login-error" style={{display:login_error}}></span>
                                            <div className="form-group text-center">
                                                <input type="text"  name='email' onChange={updateInput} value={state.user.email} className="form-control mb-3" placeholder="メールアドレス" autoComplete="email" autoFocus/>
                                            </div>
                                            <div className="form-group text-center">
                                                <input type="password" name="password" onChange={updateInput} className="form-control mb-3" placeholder="パスワード" value={state.user.password} />
                                            </div>
                                            <div className="form-group text-center mb_25" style={{fontSize:"12px"}}>
                                                <input className="ml_20" type="checkbox" value="" id="privacy" name ="privacy" style={{marginRight:"8px",marginTop:"5px"}}required/> 
                                                <a 
                                                    href="" onClick={(event) => {
                                                    event.preventDefault();
                                                    setOpen(true);
                                                }}>SESMart利用規約・個人情報</a>に関する同意書に同意する。 
                                            </div>
                                            <div className="form-group text-center mt-3">
                                                <button type="button"  onClick={login} className="login-btn" style={{backgroundColor: btnColor}} 
												disabled={disabled}>ログイン</button><br/>
                                            </div>
											 <div className="form-group text-center security mt-5" style={{fontSize: "12px",fontWeight: 700}}>
                                               
												<a href={`/resetpassword`}>
												パスワードを忘れた方 
												</a>
                                            </div>
                                            {/* <div className="form-group text-center security mt-5" style={{fontSize: "12px"}}>
                                                <a  href="https://floboard.co.jp/www/index.php/company/privacy" target="_blank">個人情報保護方針 </a>
                                                <br/> 
                                                <a href="https://floboard.co.jp/www/index.php/company/security"  target="_blank">情報セキュリティ方針</a>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                            </Col>
                       </Row>
                        </div>
                    </Col>
                </Row>
                {/* </div> */}
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'none', sm: 'block' },
					}}
                >  
                    <Box sx={PCModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>

				<Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'block', sm: 'none' },
					}}
                >  
                    <Box sx={MobileModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>
        
		
		</React.Fragment>
    )
};
export default LoginForm;