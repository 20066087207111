import axios from "axios";
import { myAxios } from "../lib/utils";
myAxios(axios);

const RESOURCE_LOGIN_URL = process.env.REACT_APP_RESOURCE_LOGIN_URL;
const RESOURCE_LOGOUT_URL = process.env.REACT_APP_RESOURCE_LOGOUT_URL;
const API_URL = process.env.REACT_APP_API_URL;
const COMPANY_CODE = process.env.REACT_APP_COMPANY_CODE;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const DATABASE_ID = process.env.REACT_APP_DATABASE_ID;
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;

const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header = {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        "client-domain" :  `${CLIENT_DOMAIN}`,
        "database-id" :  `${DATABASE_ID}`,
         "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*" ,
        "Access-Control-Allow-Headers" : 'X-Requested-With, X-HTTP-Method-Override, Content-Type, Accept'
       }
};

class AuthService {

  login(data:any) {
    let dataURL:string = API_URL + "/login";
    // data["server_url"] = SERVER_URL;
    return axios.post(dataURL, data , header);
  }

  resourceLogin(loginCode:string, uri:string){
    let redectUrl = window.location.origin+uri;
    window.location.href = `${RESOURCE_LOGIN_URL}?loginCode=${loginCode}&redectUrl=${redectUrl}&database_id=${DATABASE_ID}&service_id=${SERVICE_ID}`;
    // window.location.href = `${RESOURCE_LOGIN_URL}?loginCode=${loginCode}&redectUrl=${redectUrl}&server_url=${SERVER_URL}`;
  }
  resourceLoginCheck(){
    let rs = localStorage.getItem("rs")??"";
    return axios.get(`${RESOURCE_LOGIN_URL}Check?rs=${rs}`);
  }

  logout() {
      let rs = localStorage.getItem("rs")??"";
      // localStorage.removeItem("user"); // remove JWT from local storage
      // localStorage.removeItem("token"); // remove JWT from local storage
      // localStorage.removeItem("rs");
      // localStorage.removeItem("token_updated_at");
      // localStorage.removeItem("appWinCount");
      // localStorage.removeItem("appWinCount");
      // localStorage.removeItem("profile_image");
      localStorage.clear();
      let redectUrl = window.location.origin+"/login";
      window.location.href = `${RESOURCE_LOGOUT_URL}?rs=${rs}&redectUrl=${redectUrl}`;
  }
  resend(data:any){
    let dataURL:string = API_URL+"/verify/resend1";
    return axios.post(dataURL,data, header);
  }

  store(data:any) {
    let dataURL:string = API_URL + '/verify/store';
    // console.log("Token start");
    // console.log(token);
    // console.log("Token end");
    // data["server_url"] = SERVER_URL;
    return axios.post(dataURL, data, header);
  }

  resetPassword(data:any){
    let dataURL:string = API_URL + '/password/email';
    return axios.post(dataURL, data, header);
  }

  newPasswordChange(data:any){
    let dataURL:string = API_URL + '/password/reset';
    // console.log("token start");
    // console.log(token);
    // console.log("token end");
    return axios.post(dataURL, data, header);
  }

  
  // login(username: string, password: string) {
  //   return axios
  //     .post(API_URL + "signin", {
  //       username,
  //       password
  //     })
  //     .then(response => {
  //       if (response.data.accessToken) {
  //         localStorage.setItem("user", JSON.stringify(response.data)); // store the user login information & JWT to local storage
  //       }

  //       return response.data;
  //     });
  // }
  // logout() {
  //   localStorage.removeItem("user"); // remove JWT from local storage
  // }
  getCurrentUser() {
    const userStr = localStorage.getItem("user"); // retrieve stored user information from local storage
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

  export default new AuthService();